import React, { useState, useEffect, useContext } from 'react'
import { Link } from '@reach/router'
import { FirebaseContext } from '../../../../contexts/firebase'
import Container from '../../../../components/common/container'
import { Table, Th, Td } from '../../../../components/common/table'
import { LinkLikeButton } from '../../../../components/common/forms'
import Card from '../../../../components/common/card'

const GameOrder = ({ players }) => (
  <Card>
    <h2>Suggested order</h2>
    <Table>
      <thead>
        <tr>
          <Th scope="col">Player</Th>
          <Th scope="col" first>
            Lifetime score
          </Th>
          <Th scope="col">Magics</Th>
        </tr>
      </thead>
      <tbody>
        {players
          .sort((a, b) => (a.magicIndex > b.magicIndex ? 1 : -1))
          .map((player) => (
            <tr>
              <Th scope="row">{player.name}</Th>
              <Td first>{player.lifetimeScore}</Td>
              <Td>{player.magicIndex ? player.magicIndex : 0}</Td>
            </tr>
          ))}
      </tbody>
    </Table>
  </Card>
)

const GameEnter = ({ leagueId, gameId }) => {
  const [league, setLeague] = useState(false)
  const [game, setGame] = useState(false)
  const [players, setPlayers] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('leagues')
      .doc(leagueId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setLeague({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setGame({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
  }, [firebase, leagueId, gameId])

  useEffect(() => {
    if (!game || !game.players) {
      return
    }

    firebase
      .firestore()
      .collection('profiles')
      .where(
        'user',
        'in',
        game.players.map((player) => player.user)
      )
      .get()
      .then((querySnapshot) => {
        const profiles = []
        querySnapshot.forEach((doc) => {
          profiles.push({ _id: doc.id, ...doc.data() })
        })
        setPlayers(profiles)
      })
      .catch((e) => console.log(e))
  }, [game, firebase])

  return (
    <Container>
      {notFound ? (
        <p>Game not found</p>
      ) : (
        <>
          <h1>Enter game {game.name}</h1>
          {players && <GameOrder players={players} />}
          {game.scores && players && (
            <>
              <LinkLikeButton
                to={`/league/${league._id}/game/${game._id}/finish`}
              >
                Finish game
              </LinkLikeButton>
              {game.scores.map((hop, number) => (
                <Card>
                  <h3>Hop #{number + 1}</h3>
                  {!game.done && (
                    <>
                      <Link
                        to={`/league/${league._id}/game/${game._id}/enter/hop/edit/${number}`}
                      >
                        Edit hop
                      </Link>
                      -
                      <Link
                        to={`/league/${league._id}/game/${game._id}/enter/hop/delete/${number}`}
                      >
                        Delete hop
                      </Link>
                    </>
                  )}
                  <Table>
                    <thead>
                      <tr>
                        <Th>Player</Th>
                        <Th>Score</Th>
                        <Th>Magic</Th>
                        <Th>Magic player</Th>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.keys(hop).map((playerId) => (
                        <tr>
                          <Td>
                            {
                              players.find((player) => player._id === playerId)
                                .name
                            }
                          </Td>
                          <Td>{hop[playerId].score}</Td>
                          <Td>{hop[playerId].magic}</Td>
                          <Td>
                            {hop[playerId].magicPlayer && (
                              <>
                                {
                                  players.find(
                                    (player) =>
                                      player._id === hop[playerId].magicPlayer
                                  ).name
                                }
                              </>
                            )}
                          </Td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              ))}
            </>
          )}
          {!game.done && (
            <LinkLikeButton
              to={`/league/${league._id}/game/${game._id}/enter/hop`}
            >
              Add hop
            </LinkLikeButton>
          )}
        </>
      )}
    </Container>
  )
}

export default GameEnter
