import { Link } from '@reach/router'
import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../../contexts/firebase'
import Alert from '../common/alert'

const CheckProfile = () => {
  const [profile, setProfile] = useState(false)
  const { firebase, user } = useContext(FirebaseContext)

  useEffect(() => {
    if (!user.uid) {
      return
    }
    firebase
      .firestore()
      .collection('profiles')
      .where('user', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setProfile({ _id: doc.id, ...doc.data() })
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [firebase, user])

  if (!profile || profile.name) {
    return null
  }

  return (
    <Alert warning>
      You should <Link to="/profile">set up your profile</Link> before joining
      any leagues so folks know who you are!
    </Alert>
  )
}

export default CheckProfile
