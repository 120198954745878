import styled from '@emotion/styled'

const Container = styled.div`
  max-width: ${(props) => (props.narrow ? 700 : 1100)}px;
  margin: 0 1.5rem;
  @media (min-width: 1150px) {
    margin: 0 auto;
  }
`

export default Container
