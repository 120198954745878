import React, { useState, useEffect, useContext } from 'react'
import { navigate } from '@reach/router'
import { DateTime } from 'luxon'
import { UnstyledList } from '../../../components/common/list'
import Card from '../../../components/common/card'
import {
  Label,
  Input,
  Checkbox,
  Button,
} from '../../../components/common/forms'
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from 'unique-names-generator'

import { FirebaseContext } from '../../../contexts/firebase'
import Container from '../../../components/common/container'

const GameStart = ({ leagueId }) => {
  const defaultName = uniqueNamesGenerator({
    dictionaries: [adjectives, colors, animals],
    separator: ' ',
    style: 'capital',
  })
  const [league, setLeague] = useState(false)
  const [players, setPlayers] = useState(false)
  const [date, setDate] = useState(DateTime.now().toISODate())
  const [name, setName] = useState(defaultName)
  const [notFound, setNotFound] = useState(false)
  const [gamePlayers, setGamePlayers] = useState([])
  const { firebase, user } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('leagues')
      .doc(leagueId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setLeague({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
  }, [firebase, leagueId])

  useEffect(() => {
    if (!league) {
      return
    }
    firebase
      .firestore()
      .collection('profiles')
      .where('user', 'in', league.users)
      .get()
      .then((querySnapshot) => {
        const profiles = []
        querySnapshot.forEach((doc) => {
          profiles.push({ _id: doc.id, ...doc.data() })
        })
        setPlayers(profiles)
      })
  }, [firebase, league])

  return (
    <Container narrow>
      <Card>
        {notFound ? (
          <p>League not found</p>
        ) : (
          <>
            <h1>Start new game for {league.name}</h1>
            <form
              onSubmit={(event) => {
                event.preventDefault()
                const currentPlayers = players.filter(
                  (player) => gamePlayers.indexOf(player.user) > -1
                )
                firebase
                  .firestore()
                  .collection('games')
                  .add({
                    league: league._id,
                    players: currentPlayers,
                    playerIds: players.map((player) => player._id),
                    creator: user.uid,
                    name,
                    date,
                    hops: [],
                    scores: [],
                    done: false,
                  })
                  .then((result) =>
                    navigate(`/league/${league._id}/game/${result.id}/enter`)
                  )
              }}
            >
              <h3 id="player-group">Select players</h3>
              <UnstyledList role="group" aria-labelledby="player-group">
                {players &&
                  players.map((player) => (
                    <li>
                      <Label noBold>
                        <Checkbox
                          type="checkbox"
                          onChange={(event) => {
                            if (event.target.checked) {
                              setGamePlayers([...gamePlayers, player.user])
                            } else {
                              const copyPlayers = [...gamePlayers]
                              const index = copyPlayers.indexOf(player.user)
                              if (index > -1) {
                                copyPlayers.splice(index, 1)
                                setGamePlayers(copyPlayers)
                              }
                            }
                          }}
                        />
                        {player.name}
                      </Label>
                    </li>
                  ))}
              </UnstyledList>
              <Label htmlFor="date">Date</Label>
              <Input
                type="date"
                id="date"
                onChange={(event) => setDate(event.target.value)}
                defaultValue={DateTime.now().toISODate()}
              />
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                defaultValue={defaultName}
                onChange={(event) => setName(event.target.value)}
              />
              <Button
                type="submit"
                disabled={gamePlayers.length < 2 ? true : undefined}
              >
                {gamePlayers.length >= 2 ? (
                  <>Create Game</>
                ) : (
                  <>Select at least two players</>
                )}
              </Button>
            </form>
          </>
        )}
      </Card>
    </Container>
  )
}

export default GameStart
