import React, { useContext } from 'react'
import { Router } from '@reach/router'
import { ThemeProvider } from '@emotion/react'
import { css, Global } from '@emotion/react'
import { FirebaseProvider, FirebaseContext } from './contexts/firebase'
import Layout from './components/layout'
import theme from './style/theme'

import Homepage from './pages/index'
import Login from './pages/log-in'
import Profile from './pages/profile'
import Player from './pages/player'
import LeagueStart from './pages/league/start'
import LeagueView from './pages/league/view'
import GameStart from './pages/league/game/start'
import GameEnter from './pages/league/game/enter'
import GameView from './pages/league/game/view'
import GameFinish from './pages/league/game/finish'
import GameEnterHop from './pages/league/game/enter/hop'
import GameEditHop from './pages/league/game/enter/hop/edit'
import GameDeleteHop from './pages/league/game/enter/hop/delete'
import AllLeaguesPage from './pages/leagues'
import LogOutPage from './pages/log-out'

const LoginWrapper = () => {
  const { user, isReady } = useContext(FirebaseContext)

  return (
    <Layout>
      {isReady && (
        <>
          {user ? (
            <Router>
              <Homepage path="/" />
              <Profile path="profile" />
              <Player path="player/:playerId" />
              <LeagueStart path="league/start" />
              <LeagueView path="league/:leagueId" />
              <GameStart path="league/:leagueId/game/start" />
              <GameView path="league/:leagueId/game/:gameId" />
              <GameEnter path="league/:leagueId/game/:gameId/enter" />
              <GameFinish path="league/:leagueId/game/:gameId/finish" />
              <GameEnterHop path="league/:leagueId/game/:gameId/enter/hop" />
              <GameEditHop path="league/:leagueId/game/:gameId/enter/hop/edit/:hopId" />
              <GameDeleteHop path="league/:leagueId/game/:gameId/enter/hop/delete/:hopId" />
              <AllLeaguesPage path="leagues" />
              <LogOutPage path="log-out" />
            </Router>
          ) : (
            <Login />
          )}
        </>
      )}
    </Layout>
  )
}

const App = () => (
  <ThemeProvider theme={theme}>
    <div className="App">
      <Global
        styles={css`
          :root {
            font-size: 18px;
          }

          body {
            font-family: 'system-ui', '-apple-system', 'BlinkMacSystemFont',
              'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
              'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

            font-size: 1rem;
            margin: 0;
            padding: 0;
          }

          a,
          a:visited {
            color: ${theme.colors.link};
          }
        `}
      />
      <FirebaseProvider>
        <LoginWrapper />
      </FirebaseProvider>
    </div>
  </ThemeProvider>
)

export default App
