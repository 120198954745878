const theme = {
  colors: {
    primary: '#000',
    lightGrey: '#cfcfd3',
    lightText: '#545454',
    link: '#33e',
    button: '#3F51F0',
    buttonDisabled: '#97B3F8',
    danger: '#F64D26',
    warning: '#4370EB',
    success: '#42D499',
  },
}

export default theme
