import React, { useState, useContext, useEffect } from 'react'
import { Link } from '@reach/router'
import { FirebaseContext } from '../../contexts/firebase'
import { LinkLikeButton, Button } from '../../components/common/forms'
import GameList from '../../components/common/game-list'
import PlayerList from '../../components/common/player-list'
import Alert from '../../components/common/alert'

const LeaguePlayers = ({ league }) => {
  const [players, setPlayers] = useState(false)

  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    if (!league) {
      return
    }
    firebase
      .firestore()
      .collection('profiles')
      .where('user', 'in', league.users)
      .get()
      .then((querySnapshot) => {
        const profiles = []
        querySnapshot.forEach((doc) => {
          profiles.push({ _id: doc.id, ...doc.data() })
        })
        setPlayers(profiles)
      })
  }, [firebase, league])

  if (!players || !players.length) {
    return null
  }

  return <PlayerList players={players} />
}

const LeagueGames = ({ league }) => {
  const [games, setGames] = useState(false)

  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('games')
      .where('league', '==', league._id)
      .orderBy('date', 'desc')
      .get()
      .then((querySnapshot) => {
        const leagueGames = []
        querySnapshot.forEach((doc) => {
          leagueGames.push({ _id: doc.id, ...doc.data() })
        })
        setGames(leagueGames)
      })
  }, [firebase, league])

  if (!games || !games.length) {
    return null
  }

  return <GameList games={games} />
}

const LeagueView = ({ leagueId }) => {
  const [league, setLeague] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [profile, setProfile] = useState(false)
  const { firebase, firestore, user } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('leagues')
      .doc(leagueId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setLeague({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
    firebase
      .firestore()
      .collection('profiles')
      .where('user', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setProfile({ _id: doc.id, ...doc.data() })
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [firebase, leagueId])

  return (
    <>
      {notFound && <p>League not found</p>}
      {league ? (
        <>
          <h1>{league.name}</h1>
          {league.users.indexOf(user.uid) > -1 ? (
            <p>You are a member of this league.</p>
          ) : (
            <>
              <p>You are not a member of this league.</p>

              {profile.name ? (
                <p>
                  <Button
                    onClick={(event) => {
                      event.preventDefault()
                      firebase
                        .firestore()
                        .collection('leagues')
                        .doc(leagueId)
                        .update({
                          users: firestore.FieldValue.arrayUnion(user.uid),
                        })
                    }}
                  >
                    Join this league
                  </Button>
                </p>
              ) : (
                <Alert warning>
                  You cannot join any leagues until you{' '}
                  <Link to="/profile">you setup your name in your profile</Link>
                  .
                </Alert>
              )}
            </>
          )}
          <h2>Members</h2>
          <LeaguePlayers league={league} />
          <h2>Games</h2>
          <LinkLikeButton to={`/league/${leagueId}/game/start`}>
            Start new game
          </LinkLikeButton>
          <LeagueGames league={league} />
        </>
      ) : (
        <h1>Loading league</h1>
      )}
    </>
  )
}

export default LeagueView
