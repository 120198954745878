import styled from '@emotion/styled'

const Table = styled.table`
  border-collapse: collapse;
`

const Td = styled.td`
  padding: 0.5rem;
  ${(props) =>
    props.first &&
    `
      border-left: 1px solid ${props.theme.colors.lightGrey};
      padding-left: 1rem;`}
`

const Th = styled.th`
  font-weight: bold;
  padding: 0.5rem;
  text-align: left;
  ${(props) =>
    props.first &&
    `border-left: 1px solid ${props.theme.colors.lightGrey};
    padding-left: 1rem;`}
`

export { Table, Td, Th }
