import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../../../contexts/firebase'
import Container from '../../../components/common/container'

const GameView = ({ leagueId, gameId }) => {
  const [league, setLeague] = useState(false)
  const [game, setGame] = useState(false)
  const [players, setPlayers] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('leagues')
      .doc(leagueId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setLeague({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setGame({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
  }, [firebase, leagueId, gameId])

  useEffect(() => {
    if (!game || !game.players) {
      return
    }

    firebase
      .firestore()
      .collection('profiles')
      .where(
        'user',
        'in',
        game.players.map((player) => player.user)
      )
      .get()
      .then((querySnapshot) => {
        const profiles = []
        const profileScores = {}
        querySnapshot.forEach((doc) => {
          profiles.push({ _id: doc.id, ...doc.data() })
          profileScores[doc.id] = {
            score: 0,
            magic: false,
            magicPlayer: false,
          }
        })
        setPlayers(profiles)
      })
      .catch((e) => console.log(e))
  }, [game, firebase])

  return (
    <Container>
      {notFound || !game || !league || !players ? (
        <p>Game not found</p>
      ) : (
        <>
          <h2>
            Game {game.name} in {league.name}
          </h2>
          {game.scores.map((hop, number) => (
            <>
              <h3>Hop #{number}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Score</th>
                    <th>Magic</th>
                    <th>Magic player</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.keys(hop).map((playerId) => (
                    <tr>
                      <td>
                        {players.find((player) => player._id === playerId).name}
                      </td>
                      <td>{hop[playerId].score}</td>
                      <td>{hop[playerId].magic}</td>
                      <td>
                        {hop[playerId].magicPlayer && (
                          <>
                            {
                              players.find(
                                (player) =>
                                  player._id === hop[playerId].magicPlayer
                              ).name
                            }
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ))}
        </>
      )}
    </Container>
  )
}

export default GameView
