import React from 'react'
import Header from './header'
import Container from '../common/container'

const Layout = ({ title, children }) => (
  <>
    <Header />
    <Container>
      {title && <h1>{title}</h1>}
      <main>{children}</main>
    </Container>
  </>
)

export default Layout
