import React, { useEffect, useContext, useState } from 'react'
import { FirebaseContext } from '../../contexts/firebase'
import GameList from './game-list'

const PlayerGames = () => {
  const [games, setGames] = useState(false)

  const { firebase, user } = useContext(FirebaseContext)

  useEffect(() => {
    if (!user) {
      return
    }
    firebase
      .firestore()
      .collection('profiles')
      .where('user', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        let profile = false
        querySnapshot.forEach((doc) => {
          profile = { _id: doc.id, ...doc.data() }
        })
        if (!profile) {
          return
        }
        firebase
          .firestore()
          .collection('games')
          .where('playerIds', 'array-contains', profile._id)
          .get()
          .then((querySnapshot) => {
            const userGames = []
            querySnapshot.forEach((doc) => {
              userGames.push({ _id: doc.id, ...doc.data() })
            })
            setGames(userGames)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [firebase, user])

  if (!games || !games.length) {
    return null
  }
  return <GameList games={games} />
}

export default PlayerGames
