import React from 'react'
import Player from './player'
import styled from '@emotion/styled'

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`

const PlayerList = ({ players }) => (
  <List>
    {players.map((player) => (
      <li key={player._id}>
        <Player player={player} />
      </li>
    ))}
  </List>
)

export default PlayerList
