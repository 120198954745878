import React, { useEffect, useContext, useState } from 'react'
import LeagueList from './league-list'
import { FirebaseContext } from '../../contexts/firebase'

const PlayerLeagues = () => {
  const [leagues, setLeagues] = useState(false)

  const { firebase, user } = useContext(FirebaseContext)

  useEffect(() => {
    if (!user) {
      return
    }
    firebase
      .firestore()
      .collection('leagues')
      .where('users', 'array-contains', user.uid)
      .get()
      .then((querySnapshot) => {
        const userLeagues = []
        querySnapshot.forEach((doc) => {
          userLeagues.push({ _id: doc.id, ...doc.data() })
        })
        setLeagues(userLeagues)
      })
  }, [firebase, user])

  return <LeagueList leagues={leagues} />
}

export default PlayerLeagues
