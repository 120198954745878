const magic = [
  {
    id: 'beetlejuice',
    name: 'Beetlejuice',
    targetScore: (score) => Math.round(score * 0.2),
  },
  {
    id: 'grapevine',
    name: 'Grapevine',
  },
  { id: 'multiball', name: 'Multiball' },
]

export default magic
