import React from 'react'
import { Link } from '@reach/router'
import styled from '@emotion/styled'

const PlayerRow = styled.div`
  display: flex;
  align-items: center;
`

const Profile = styled.img`
  display: inline-block;
  width: 50px;
  margin-right: 1rem;
`

const Name = styled.span`
  display: inline-block;
`

const Player = ({ player }) => (
  <Link to={`/player/${player._id}`}>
    <PlayerRow>
      {player.image && <Profile src={player.image} />}
      <Name>{player.name}</Name>
    </PlayerRow>
  </Link>
)

export default Player
