import React, { useState, useContext } from 'react'
import { navigate } from '@reach/router'
import { Label, Input, Button } from '../../components/common/forms'
import { FirebaseContext } from '../../contexts/firebase'
import Container from '../../components/common/container'
import Card from '../../components/common/card'

const StartLeague = () => {
  const [name, setName] = useState(false)
  const { firebase, user } = useContext(FirebaseContext)

  return (
    <Container narrow>
      <Card>
        <h2>Start a new league</h2>
        <p>
          Leagues are easy ways to group together people who play Grasshopper
          together.
        </p>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            firebase
              .firestore()
              .collection('leagues')
              .add({
                name,
                creator: user.uid,
                users: [user.uid],
              })
              .then((result) => navigate(`/league/${result.id}`))
          }}
        >
          <Label htmlFor="name">League name</Label>
          <Input
            type="text"
            onChange={(event) => setName(event.target.value)}
          />
          <Button type="submit">Create League</Button>
        </form>
      </Card>
    </Container>
  )
}

export default StartLeague
