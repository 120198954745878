import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../contexts/firebase'
import { Input, Button, Label } from '../components/common/forms'
import Alert from '../components/common/alert'
import Container from '../components/common/container'
import styled from '@emotion/styled'

const ProfileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  input {
    @media (min-width: 1000px) {
      width: 50%;
    }
  }
  img {
    @media (min-width: 1000px) {
      width: 30%;
    }
  }
`

const ProfileForm = ({ profile }) => {
  const [name, setName] = useState(profile.name || '')
  const [image, setImage] = useState(profile.image || null)
  const [isUpdated, setIsUpdated] = useState(false)
  const { firebase, storage } = useContext(FirebaseContext)

  return (
    <>
      {isUpdated && <Alert success>Your profile has been updated.</Alert>}
      <form
        onSubmit={(event) => {
          event.preventDefault()
          if (image) {
            const storageRef = storage.ref(`/profile-images/${image.name}`)
            storageRef.put(image)

            storageRef.getDownloadURL().then((url) => {
              firebase
                .firestore()
                .collection('profiles')
                .doc(profile._id)
                .update({
                  name,
                  image: url,
                })
                .then(() => setIsUpdated(true))
            })
          } else {
            firebase
              .firestore()
              .collection('profiles')
              .doc(profile._id)
              .update({
                name,
              })
              .then(() => setIsUpdated(true))
          }
        }}
      >
        <Label htmlFor="name">Your public name</Label>
        <Input
          type="text"
          defaultValue={profile.name || ''}
          onChange={(event) => setName(event.target.value)}
        />
        <Label htmlFor="image">Profile image</Label>
        <ProfileWrapper>
          <Input
            type="file"
            name="image"
            id="image"
            onChange={(event) => {
              setImage(event.target.files[0])
            }}
          />
          {profile.image && <img src={profile.image} alt="Existing profile" />}
        </ProfileWrapper>

        <Button type="submit">Update profile</Button>
      </form>
    </>
  )
}

const Profile = () => {
  const [profile, setProfile] = useState(false)
  const { firebase, user } = useContext(FirebaseContext)

  useEffect(() => {
    if (!user.uid) {
      return
    }
    firebase
      .firestore()
      .collection('profiles')
      .where('user', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setProfile({ _id: doc.id, ...doc.data() })
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [firebase, user])

  return (
    <Container>
      {profile && (
        <div>
          <h2>Your profile</h2>
          <ProfileForm profile={profile} />
        </div>
      )}
    </Container>
  )
}

export default Profile
