import React, { useState, useContext } from 'react'
import { FirebaseContext } from '../contexts/firebase'
import Card from '../components/common/card'
import Container from '../components/common/container'
import { Label, Input, LinkButton, Button } from '../components/common/forms'
import { Centered, LightText } from '../components/common/text'

const Login = () => {
  const [isLogin, setIsLogin] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { firebase, user } = useContext(FirebaseContext)

  if (user) {
    return null
  }

  return (
    <Container narrow>
      <Card>
        <h2>{isLogin ? 'Log in' : 'Sign up'}</h2>
        <form>
          {success && <p>SUCCESS!</p>}
          <Label htmlFor="login">Email</Label>
          <Input
            type="text"
            id="login"
            onChange={(event) => setEmail(event.target.value)}
          />
          <Label htmlFor="password">Password</Label>
          <Input
            type="password"
            id="password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            type="submit"
            onClick={(event) => {
              event.preventDefault()
              if (isLogin) {
                firebase
                  .auth()
                  .signInWithEmailAndPassword(email, password)
                  .then((userCredential) => {
                    setSuccess(true)
                  })
                  .catch((createError) => {
                    setError(createError.message)
                  })
              }
              firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((createdUser) => {
                  setSuccess(true)
                  firebase.firestore().collection('profiles').add({
                    user: createdUser.user.uid,
                    name: null,
                    photo: null,
                    lifetimeScore: 0,
                    totalGames: 0,
                    magicIndex: 0,
                  })
                })
                .catch((createError) => {
                  setError(createError.message)
                })
            }}
          >
            {isLogin ? <>Log in</> : <>Sign up</>}
          </Button>

          {error && <p>{error}</p>}
          <Centered>
            {isLogin ? (
              <>
                <LightText>No account? </LightText>
                <LinkButton type="button" onClick={() => setIsLogin(false)}>
                  Sign up
                </LinkButton>
              </>
            ) : (
              <LinkButton type="button" onClick={() => setIsLogin(true)}>
                Wait I have an account
              </LinkButton>
            )}
          </Centered>
        </form>
      </Card>
    </Container>
  )
}

export default Login
