import styled from '@emotion/styled'
import { Link } from '@reach/router'

const Input = styled.input`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 3px;
  display: block;
  padding: ${(props) => (props.small ? `0.2rem` : `0.8rem`)};
  width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
  font-size: ${(props) => (props.small ? `0.9rem` : `1.1rem`)};
  &[type='file'] {
    border: none;
    padding: 0.8rem 0;
  }
`

const Label = styled.label`
  ${(props) => !props.noBold && 'font-weight: bold;'}
  display: block;
  margin-bottom: 0.5rem;
`

const LinkButton = styled.button`
  border: 0;
  display: inline-block;
  padding: 0;
  color: ${(props) => props.theme.colors.link};
  text-decoration: underline;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
`

const buttonStyles = (colors, danger) => `
border: 0;
border-radius: 4px;
font-weight: bold;
background: ${danger ? colors.danger : colors.button};
color: white !important;
font-size: 1rem;
padding: 1rem;
display: inline-block;
cursor: pointer;
&[disabled] {
  cursor: not-allowed;
  background: ${colors.buttonDisabled};
}
`

const Button = styled.button`
  ${(props) => buttonStyles(props.theme.colors, props.danger)}
`

const LinkLikeButton = styled(Link)`
  ${(props) => buttonStyles(props.theme.colors)}
  text-decoration: none;
`

const Checkbox = styled.input`
  display: inline-block;
  margin-right: 0.5rem;
`

export { Label, Input, LinkButton, Button, LinkLikeButton, Checkbox }
