import React from 'react'
import { Link } from '@reach/router'
import styled from '@emotion/styled'

const Leagues = styled.ul`
  list-style-type: none;
  padding: 0;
  li {
    padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    a {
      font-weight: bold;
      display: inline-block;
      width: 50%;
    }
  }
`

const LeagueList = ({ leagues }) => {
  if (!leagues || leagues.length === 0) {
    return null
  }
  return (
    <Leagues>
      {leagues.map(({ _id, name }) => (
        <li key={_id}>
          <Link to={`/league/${_id}`}>{name}</Link>
        </li>
      ))}
    </Leagues>
  )
}

export default LeagueList
