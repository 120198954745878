import styled from '@emotion/styled'

const Centered = styled.p`
  text-align: center;
`

const LightText = styled.span`
  color: ${(props) => props.theme.colors.lightText};
`

export { Centered, LightText }
