import { DateTime } from 'luxon'

const formatDate = (date) => {
  if (!date) {
    return null
  }
  return DateTime.fromISO(date).toFormat('LLLL d, yyyy')
}

export default formatDate
