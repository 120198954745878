import React, { useState, useContext, useEffect } from 'react'
import { Link } from '@reach/router'
import { FirebaseContext } from '../contexts/firebase'
import Container from '../components/common/container'
import PlayerLeagues from '../components/common/player-leagues'
import PlayerGames from '../components/common/player-games'
import PlayerStats from '../components/common/player-stats'
import CheckProfile from '../components/pages/check-profile'

const Homepage = () => {
  const [profile, setProfile] = useState(false)
  const { firebase, user } = useContext(FirebaseContext)

  useEffect(() => {
    if (!user.uid) {
      return
    }
    firebase
      .firestore()
      .collection('profiles')
      .where('user', '==', user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setProfile({ _id: doc.id, ...doc.data() })
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [firebase, user])

  return (
    <Container>
      <CheckProfile />
      <p>
        <Link to="/league/start">Start league</Link>
      </p>
      <p>
        <Link to="/leagues">All Leagues</Link>
      </p>
      <h2>Stats</h2>
      <PlayerStats player={profile} />
      <h2>Your Leagues</h2>
      <PlayerLeagues />
      <h2>Your games</h2>
      <PlayerGames />
    </Container>
  )
}

export default Homepage
