import React, { useContext } from 'react'
import { Link } from '@reach/router'
import { FirebaseContext } from '../../contexts/firebase'
import styled from '@emotion/styled'
import Container from '../common/container'
import {
  Menu,
  MenuPopover,
  MenuButton,
  MenuItems,
  MenuLink,
} from '@reach/menu-button'

const Head = styled.header`
  border-bottom: 1px solid grey;
  padding: 1.5rem 0;
`

const UserMenuButton = styled(MenuButton)`
  background: none;
  border: none;
  text-decoration: underline;
  padding: none;
  font-size: 1rem;
  cursor: pointer;
  span {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    text-decoration: none;
  }
  .toggle-down {
    display: inline-block;
  }
  .toggle-up {
    display: none;
  }
  &[aria-expanded='true'] {
    .toggle-up {
      display: inline-block;
    }
    .toggle-down {
      display: none;
    }
  }
`

const Brand = styled.div`
  font-weight: bold;
  width: 50%;
  a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      margin-left: 1rem;
    }
    img {
      max-width: 1.5rem;
    }
  }
`

const Wrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
`

const Tools = styled.div`
  width: 50%;
  text-align: right;
`

const UserMenu = styled(Menu)`
  position: relative;
`

const UserMenuPopover = styled(MenuPopover)`
  display: block;
  position: absolute;
  min-width: 160px;
  &[hidden] {
    display: none;
  }
`

const UserMenuItems = styled(MenuItems)`
  display: block;
  white-space: nowrap;
  border: solid 1px ${(props) => props.theme.colors.lightGrey};
  background: #fff;
  outline: none;
  padding: 0.5rem 0;
`

const UserMenuLink = styled(MenuLink)`
  display: block;
  user-select: none;
  cursor: pointer;
  color: #000;
  text-decoration: underline;
  padding: 0.5rem 0.5rem;
  &a,
  a:visited {
    color: #000;
  }
  &[data-selected] {
    background: ${(props) => props.theme.colors.link};
    color: white;
    outline: none;
  }
`

const Header = () => {
  const { user } = useContext(FirebaseContext)

  return (
    <Head>
      <Container>
        <Wrapper>
          <Brand>
            <Link to="/">
              <img src="/icon.png" alt="" aria-hidden />
              <span>Grasshopper</span>
            </Link>
          </Brand>
          <Tools>
            {user && (
              <UserMenu>
                <UserMenuButton>
                  {user.email}
                  <span className="toggle-down" aria-hidden>
                    ▼
                  </span>
                  <span className="toggle-up" aria-hidden>
                    ▲
                  </span>
                </UserMenuButton>
                <UserMenuPopover>
                  <UserMenuItems>
                    <UserMenuLink as={Link} to="/profile">
                      Edit profile
                    </UserMenuLink>
                    <UserMenuLink as={Link} to="/leagues">
                      Leagues
                    </UserMenuLink>
                    <UserMenuLink as={Link} to="/log-out">
                      Log out
                    </UserMenuLink>
                  </UserMenuItems>
                </UserMenuPopover>
              </UserMenu>
            )}
          </Tools>
        </Wrapper>
      </Container>
    </Head>
  )
}

export default Header
