import React, { useState, useEffect, useContext } from 'react'
import { navigate } from '@reach/router'
import { FirebaseContext } from '../../../../../contexts/firebase'
import Container from '../../../../../components/common/container'
import magic from '../../../../../lib/magic'

const GameHopEnter = ({ leagueId, gameId, hopId = false }) => {
  const [league, setLeague] = useState(false)
  const [game, setGame] = useState(false)
  const [hop, setHop] = useState(false)
  const [players, setPlayers] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [scores, setScores] = useState({})
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('leagues')
      .doc(leagueId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setLeague({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setGame({ _id: doc.id, ...doc.data() })
            setHop(doc.data().scores[hopId])
            setScores(doc.data().scores[hopId])
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
  }, [firebase, leagueId, gameId, hopId])

  useEffect(() => {
    if (!game || !game.players) {
      return
    }

    firebase
      .firestore()
      .collection('profiles')
      .where(
        'user',
        'in',
        game.players.map((player) => player.user)
      )
      .get()
      .then((querySnapshot) => {
        const profiles = []
        querySnapshot.forEach((doc) => {
          profiles.push({ _id: doc.id, ...doc.data() })
        })
        setPlayers(profiles)
      })
      .catch((e) => console.log(e))
  }, [game, firebase])

  return (
    <Container>
      {notFound ? (
        <p>Game not found</p>
      ) : (
        <>
          <h2>
            Edit hop #{parseInt(hopId, 10) + 1} of {game.name}
          </h2>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              const newScores = [...game.scores]
              newScores[hopId] = scores
              firebase
                .firestore()
                .collection('games')
                .doc(game._id)
                .update({
                  scores: newScores,
                })
                .then((result) => {
                  navigate(`/league/${league._id}/game/${game._id}/enter`)
                })
            }}
          >
            {players && (
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Score</th>
                    <th>Magic</th>
                    <th>Beetlejuice</th>
                  </tr>
                </thead>
                <tbody>
                  {players
                    .sort((a, b) =>
                      a.magicIndex > b.magicIndex ? 1 : a._id > b._id ? 1 : -1
                    )
                    .map(({ name, _id }) => (
                      <tr key={_id}>
                        <td>{name}</td>
                        <td>
                          <input
                            type="number"
                            defaultValue={hop ? hop[_id].score : undefined}
                            onChange={(event) => {
                              const newScores = { ...scores }
                              newScores[_id].score = parseInt(
                                event.target.value,
                                10
                              )
                              setScores(newScores)
                            }}
                          />
                        </td>
                        <td>
                          <select
                            onChange={(event) => {
                              const newScores = { ...scores }
                              newScores[_id].magic = event.target.value
                              setScores(newScores)
                            }}
                          >
                            <option value={false}>--- None ---</option>
                            {magic.map(({ id, name }) => (
                              <option
                                value={id}
                                selected={
                                  hop ? hop[_id].magic === id : undefined
                                }
                              >
                                {name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          {typeof scores[_id] !== 'undefined' &&
                            scores[_id].magic === 'beetlejuice' && (
                              <select
                                onChange={(event) => {
                                  scores[_id].magicPlayer = event.target.value
                                  setScores(scores)
                                }}
                              >
                                <option value={false}>--- None ---</option>
                                {players
                                  .filter((subPlayer) => subPlayer._id !== _id)
                                  .map((subPlayer) => (
                                    <option
                                      value={subPlayer._id}
                                      selected={
                                        hop
                                          ? hop[_id].magicPlayer ===
                                            subPlayer._id
                                          : undefined
                                      }
                                    >
                                      {subPlayer.name}
                                    </option>
                                  ))}
                              </select>
                            )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            <button type="submit">Save hop</button>
          </form>
        </>
      )}
    </Container>
  )
}

export default GameHopEnter
