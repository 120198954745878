import React, { useState, useEffect, useContext } from 'react'
import { navigate } from '@reach/router'
import { FirebaseContext } from '../../../../../contexts/firebase'
import Container from '../../../../../components/common/container'

const GameHopDelete = ({ leagueId, gameId, hopId }) => {
  const [league, setLeague] = useState(false)
  const [game, setGame] = useState(false)
  const [hop, setHop] = useState(false)
  const [players, setPlayers] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const { firebase, firestore } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .firestore()
      .collection('leagues')
      .doc(leagueId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setLeague({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setGame({ _id: doc.id, ...doc.data() })
            setHop(doc.data().scores[hopId])
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
  }, [firebase, leagueId, gameId, hopId])

  useEffect(() => {
    if (!game || !game.players) {
      return
    }

    firebase
      .firestore()
      .collection('profiles')
      .where(
        'user',
        'in',
        game.players.map((player) => player.user)
      )
      .get()
      .then((querySnapshot) => {
        const profiles = []
        const profileScores = {}
        querySnapshot.forEach((doc) => {
          profiles.push({ _id: doc.id, ...doc.data() })
          profileScores[doc.id] = {
            score: 0,
            magic: false,
            magicPlayer: false,
          }
        })
        setPlayers(profiles)
      })
      .catch((e) => console.log(e))
  }, [game, firebase])

  return (
    <Container>
      {notFound ? (
        <p>Game not found</p>
      ) : (
        <>
          <h2>
            Delete hop #{parseInt(hopId, 10) + 1} of {game.name}
          </h2>
          {hop && (
            <form
              onSubmit={(event) => {
                event.preventDefault()
                firebase
                  .firestore()
                  .collection('games')
                  .doc(game._id)
                  .update({
                    scores: firestore.FieldValue.arrayRemove(hop),
                  })
                  .then((result) => {
                    navigate(`/league/${league._id}/game/${game._id}/enter`)
                  })
              }}
            >
              <button type="submit">Delete hop</button>
              {players && (
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Score</th>
                      <th>Magic</th>
                      <th>Beetlejuice</th>
                    </tr>
                  </thead>
                  <tbody>
                    {players
                      .sort((a, b) =>
                        a.magicIndex > b.magicIndex ? 1 : a._id > b._id ? 1 : -1
                      )
                      .map(({ name, _id }) => (
                        <tr key={_id}>
                          <td>{name}</td>
                          <td>{hop[_id].score}</td>
                          <td>{hop[_id].magic}</td>
                          <td>{hop[_id].magicPlayer}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </form>
          )}
        </>
      )}
    </Container>
  )
}

export default GameHopDelete
