import ReachAlert from '@reach/alert'
import styled from '@emotion/styled'
import Color from 'color'

const Alert = styled(ReachAlert)`
  margin: 2rem 0;
  border-radius: 4px;
  padding: 1.5rem;
  ${(props) =>
    props.warning &&
    `
    background: ${Color(props.theme.colors.warning).lighten(0.6).hex()};
    border: 1px solid ${props.theme.colors.warning};
  `}
  ${(props) =>
    props.danger &&
    `
    background: ${Color(props.theme.colors.danger).lighten(0.7).hex()};
    border: 1px solid ${props.theme.colors.danger};
  `}

  ${(props) =>
    props.success &&
    `
    background: ${Color(props.theme.colors.success).lighten(0.7).hex()};
    border: 1px solid ${props.theme.colors.success};
  `}
`

export default Alert
