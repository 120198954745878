import React from 'react'
import styled from '@emotion/styled'

const List = styled.dl`
  div {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 0.5rem 0;
  }
  dt {
    width: 300px;
    font-weight: bold;
  }
`

const PlayerStats = ({ player }) => {
  if (!player) {
    return null
  }
  return (
    <List>
      <div>
        <dt>Total games</dt>
        <dd>{player.totalGames}</dd>
      </div>
      <div>
        <dt>Lifetime score</dt>
        <dd>{player.lifetimeScore}</dd>
      </div>
      <div>
        <dt>Magic index</dt>
        <dd>{player.magicIndex}</dd>
      </div>
    </List>
  )
}

export default PlayerStats
