import React, { useState, useEffect, useContext } from 'react'
import { FirebaseContext } from '../contexts/firebase'
import Container from '../components/common/container'
import PlayerStats from '../components/common/player-stats'

const Player = ({ playerId }) => {
  const [profile, setProfile] = useState(false)
  const { firebase, user } = useContext(FirebaseContext)

  useEffect(() => {
    if (!playerId) {
      return
    }
    firebase
      .firestore()
      .collection('profiles')
      .doc(playerId)
      .onSnapshot((doc) => {
        setProfile({ _id: doc.id, ...doc.data() })
      })
  }, [firebase, playerId])

  return (
    <Container>
      {profile && (
        <div>
          <h2>{profile.name}</h2>
          <PlayerStats player={profile} />
        </div>
      )}
    </Container>
  )
}

export default Player
