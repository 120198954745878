import React from 'react'
import Container from '../components/common/container'
import AllLeagues from '../components/common/all-leagues'
import PlayerLeagues from '../components/common/player-leagues'

const LeaguesPage = () => {
  return (
    <Container>
      <h2>Your leagues</h2>
      <PlayerLeagues />
      <h2>All leagues</h2>
      <AllLeagues />
    </Container>
  )
}

export default LeaguesPage
