import styled from '@emotion/styled'

const Card = styled.div`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 4px;
  padding: 1rem;
  margin: 3rem 0;
  h2:first-of-type {
    margin-top: 0;
  }
`

export default Card
