import React from 'react'
import { Link } from '@reach/router'
import styled from '@emotion/styled'
import formatDate from '../../lib/date'

const Games = styled.ul`
  list-style-type: none;
  padding: 0;
  li {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    a {
      font-weight: bold;
      display: inline-block;
      width: 50%;
    }
  }
`

const GameDate = styled.span`
  display: inline-block;
  width: 20%;
  margin-right: 2rem;
`

const InProgress = styled.span`
  display: inline-block;
  width: 10%;
  text-align: right;
`

const GameList = ({ games }) => (
  <Games>
    {games.map((game) => (
      <li key={game._id}>
        <GameDate>{formatDate(game.date)}</GameDate>
        <Link to={`/league/${game.league}/game/${game._id}`}>{game.name}</Link>
        {!game.done && <InProgress>In progress</InProgress>}
      </li>
    ))}
  </Games>
)

export default GameList
