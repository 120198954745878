import React, { useState, useEffect, useContext } from 'react'
import { navigate } from '@reach/router'
import { FirebaseContext } from '../../../contexts/firebase'
import Container from '../../../components/common/container'
import { Button } from '../../../components/common/forms'

const GameFinish = ({ leagueId, gameId }) => {
  const [league, setLeague] = useState(false)
  const [game, setGame] = useState(false)
  const [players, setPlayers] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    const calculateLifetimeScores = firebase
      .functions()
      .httpsCallable('calculateLifetimeScores')
    firebase
      .firestore()
      .collection('leagues')
      .doc(leagueId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setLeague({ _id: doc.id, ...doc.data() })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
    firebase
      .firestore()
      .collection('games')
      .doc(gameId)
      .onSnapshot(
        (doc) => {
          if (doc.data()) {
            setGame({ _id: doc.id, ...doc.data() })
            firebase.firestore().collection('games').doc(doc.id).update({
              done: true,
            })
            calculateLifetimeScores({
              players: doc.data().players.map((player) => player._id),
            })
          } else {
            setNotFound(true)
          }
        },
        (error) => {
          setNotFound(true)
        }
      )
  }, [firebase, leagueId, gameId])

  useEffect(() => {
    if (!game || !game.players) {
      return
    }

    firebase
      .firestore()
      .collection('profiles')
      .where(
        'user',
        'in',
        game.players.map((player) => player.user)
      )
      .get()
      .then((querySnapshot) => {
        const profiles = []
        const profileScores = {}
        querySnapshot.forEach((doc) => {
          let gameScore = 0
          game.scores.forEach((hop) => {
            if (typeof hop[doc.id] === 'undefined') {
              return
            }
            gameScore += hop[doc.id].score
          })
          profiles.push({ _id: doc.id, ...doc.data(), gameScore })
          profileScores[doc.id] = {
            score: 0,
            magic: false,
            magicPlayer: false,
          }
        })
        setPlayers(profiles)
      })
      .catch((e) => console.log(e))
  }, [game, firebase])

  return (
    <Container>
      {notFound ? (
        <p>Game not found</p>
      ) : (
        <>
          <h2>Finish {game.name}</h2>
          <Button
            danger
            onClick={() => {
              firebase
                .firestore()
                .collection('games')
                .doc(game._id)
                .update({
                  done: false,
                })
                .then(() => {
                  navigate(`/league/${league._id}/game/${game._id}/enter`)
                })
            }}
          >
            Wait, we need to enter more hops!
          </Button>
          <p>Final scores</p>
          {players && (
            <ul>
              {players.map((player) => (
                <li>
                  {player.name}: {player.gameScore}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </Container>
  )
}

export default GameFinish
