import React, { createContext, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'

import firebaseConfig from '../firebase-config'

const FirebaseContext = createContext()

const app = firebase.initializeApp(firebaseConfig)

if (window.location.hostname === 'localhost') {
  firebase.auth().useEmulator('http://localhost:9099/')
  firebase.firestore().useEmulator('localhost', 8080)
  firebase.functions().useEmulator('localhost', 5001)
  firebase.storage().useEmulator('localhost', 9199)
}

const FirebaseProvider = ({ children }) => {
  const [currentUser, setCurrentuser] = useState(false)
  const [isReady, setIsReady] = useState(false)
  firebase.auth().onAuthStateChanged((user) => {
    setIsReady(true)
    setCurrentuser(user)
  })
  const db = firebase.firestore()
  firebase.analytics()

  const storage = firebase.storage()
  return (
    <FirebaseContext.Provider
      value={{
        firebase: app,
        firestore: firebase.firestore,
        db,
        storage,
        user: currentUser,
        isReady,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

export { FirebaseProvider, FirebaseContext, firebaseConfig }
