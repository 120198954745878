import React, { useContext, useState, useEffect } from 'react'
import { FirebaseContext } from '../contexts/firebase'
import Card from '../components/common/card'
import Container from '../components/common/container'
import { navigate } from '@reach/router'

const Login = () => {
  const [signedOut, setSignedOut] = useState(false)
  const { firebase } = useContext(FirebaseContext)

  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setSignedOut(true)
        navigate('/')
      })
      .catch(() => {
        setSignedOut(true)
        navigate('/')
      })
  })

  return (
    <Container narrow>
      <Card>
        <h2>{signedOut ? 'Signed out' : 'Signing out...'}</h2>
      </Card>
    </Container>
  )
}

export default Login
